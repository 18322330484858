import { useState } from "react";
import { DropdownMenuHC } from "../Menus/DropdownHC";

const DropdownWithSelectionBox = ({
  dropdownOptions,
  placeholder,
  title,
  classNames,
  dropdownPosition,
  itemName,
  jobDetails,
  setJobDetails,
}) => {
  const [selectedItems, setSelectedItems] = useState(jobDetails[itemName])

  const handleSelection = (items) => {
    setSelectedItems(items);
    setJobDetails(itemName, items)
  };


  const DisplaySelectedItemsList = () => {
    if (!selectedItems.length) return <p className="text-gray-500 text-sm text-center">No items selected</p>

    const getItemName = (item) => {
      return dropdownOptions.find((option) => option.id === item)?.name
    }

    return selectedItems.map((item, index) => (
      <div
        key={index}
        className="text-md text-blue-500 bg-gray-50 shadow-md border border-gray-300 rounded-md p-2"
      >
        {getItemName(item)}
      </div>
    ))
  }


  return (
    <div className={`w-full flex flex-col ${dropdownPosition === "right" ? "md:items-end " : "md:items-start "}`}>
      <DropdownMenuHC
        dropdownOptions={dropdownOptions}
        selector={selectedItems}
        setSelector={handleSelection}
        arrowDirectionUp={false}
        dropdownPosition={dropdownPosition}
        type="checkbox"
        placeholder={placeholder}
        classNames={classNames}
        itemName={itemName}
      />
      <div className="w-full sm:w-[100%] md:w-[90%] flex flex-col border rounded-t-xl py-4 mt-4 divide-y overflow-hidden">
        <h1 className="pb-2 text-center text-gray-900 font-semibold text-lg">
          {title}
        </h1>
        <div className="p-2 flex flex-col gap-2 overflow-y-auto max-h-[300px] h-[200px]">
          <DisplaySelectedItemsList />
        </div>
      </div>
    </div>
  );
};


export { DropdownWithSelectionBox };
