import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiOperation } from '../../hooks/useApiOperation';
import { JobsApi } from '../../lib/jobs.api';
import { Table } from '../../utils/shared/table/table';
import { PrimaryButton } from '../Button/PrimaryButton';
import { Loader } from '../Loader/Loader';
import { ArrowDownIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

const getJobsHeader = () => {
  return [
    { name: "Address", ref: "address" },
    { name: "Chemicals", ref: "chemicals" },
    { name: "Status", ref: "status" },
    { name: "Technician     ", ref: "technician" },
    { name: "Date", ref: "date" },
  ];
};

const DashboardTable = () => {
  const [jobs, setJobs] = useState([])
  const [expandedRow, setExpandedRow] = useState(false);
  const navigate = useNavigate();
  const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation()

  useEffect(() => {
    fetchLatestJobs()
  }, [])

  const fetchLatestJobs = async () => {
    try {
      startApiOperation()
      const data = await JobsApi.getLatestJobs()
      setJobs(data);
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation(['An error occurred while fetching latest jobs. Please try again later.'])
    }
  };

  const getJobsData = () => {
    const { jobs: latestJobs } = jobs
    const currentData = [];

    latestJobs?.map((job) => {
      return currentData.push({
        address: (
          <div className="flex items-center">
            <a className="hover:text-blue-500" href={`./job-details/${job?._id}`}>
              <p className="text-sm">{job?.address?.address}</p>
            </a>
          </div>
        ),
        Chemicals: (
          <div className={`h-6 overflow-hidden flex items-center ${expandedRow && 'h-auto'}`}>
            <span className='pt-2'>
              {job.chemicals.map((chemical) => (
                <p key={chemical._id} className="text-sm">{chemical.chemicalName}</p>
              ))}
            </span>
            {job.chemicals.length > 1 && <ChevronDownIcon className='w-5 h-5' onClick={() => setExpandedRow(!expandedRow)} />}
          </div>
        ),
        status: (
          <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${job.active === true ? "bg-blue-100 text-blue-800" : "bg-green-100 text-green-800"}`}>
            {job.active === true ? "In-Progress" : "Completed"}
          </span>
        ),
        technician: (job?.user ? job.user.username : job?.technician[0]?.username) ?? job?.user?.username,
        date: dayjs(job.startTime).format("DD MMM YYYY"),
        actions: <></>,
      });
    });

    return currentData;
  };

  const tableData = {
    headers: getJobsHeader(),
    tabledata: getJobsData(),
  };

  const DisplayShowMore = () => {
    if (!jobs?.totalJobs > 8) return null
    return <div className="flex items-center justify-center mt-4">
      <PrimaryButton
        label="Show More"
        onClickEvent={() => navigate("/jobs")}
        displayPlus={false}
      />
    </div>

  }

  if (apiLoading) return <Loader />
  return (
    <div >
      <h1 className='text-2xl text-gray-800 border-b font-semibold p-2 mt-4'>Recent Jobs</h1>
      <Table {...tableData} />
      <DisplayShowMore />
    </div>
  )
}

export { DashboardTable };
