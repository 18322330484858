import React from 'react'
import { Loader } from '../Loader/Loader'

const AvailableVersions = ({ versions, loading }) => {
  const VersionsList = () => {
    if (!versions) return null
    return versions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item, index) => (
      <div key={index} className="text-md text-sky-700 bg-gray-50 shadow-md border border-gray-300 rounded-md p-2 flex">
        {item.name}
      </div>
    ))
  }

  const DisplayLoader = () => {
    if (!loading) return null
    return <Loader />
  }

  return (
    <div className="h-full flex flex-col flex-wrap w-1/4 border rounded-xl p-4 divide-y">
      <h1 className="pb-2 text-center text-gray-900 font-semibold text-lg">Available Versions</h1>
      <div className="py-2 flex flex-col gap-2 overflow-y-auto max-h-[93%] w-full">
        <DisplayLoader />
        <VersionsList />
      </div>
    </div>
  )
}

export { AvailableVersions }
