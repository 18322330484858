import { HTTP_METHODS } from "../utils/constants";
import { sendRequest } from "../utils/request-service";

const API_REQUESTS = {
  ADD_VERSION: {
    path: '/versions/createVersion',
    method: HTTP_METHODS.POST,
  },
  SEARCH_VERSIONS: {
    path: '/versions/getAll',
    method: HTTP_METHODS.GET,
  },
  GET_VERSION_BY_ID: {
    path: '/versions/get',
    method: HTTP_METHODS.GET,
  },
}

export const VersionsApi = {
  addVersion: (data) => {
    return sendRequest(
      API_REQUESTS.ADD_VERSION.method,
      `${API_REQUESTS.ADD_VERSION.path}`,
      data,
      'multipart/form-data',
    );
  },
  getVersions: () => {
    return sendRequest(
      API_REQUESTS.SEARCH_VERSIONS.method,
      `${API_REQUESTS.SEARCH_VERSIONS.path}`,
    );
  },
  getVersionById: (params) => {
    return sendRequest(
      API_REQUESTS.SEARCH_VERSIONS.method,
      `${API_REQUESTS.SEARCH_VERSIONS.path}`,
      { params: params }
    );
  },
}


