import { HTTP_METHODS } from "../utils/constants";
import { sendRequest } from "../utils/request-service";

export const API_REQUESTS = {
  GET_DATA: {
    path: '/data/allData',
    method: HTTP_METHODS.GET,
  },
  GET_DATA_BY_ID: {
    path: '/data/getDataById',
    method: HTTP_METHODS.GET,
  },
};

export const DataApi = {
  fetchData: (params) => {
    return sendRequest(
      API_REQUESTS.GET_DATA.method,
      `${API_REQUESTS.GET_DATA.path}`,
      { params: params }
    );
  },
  fetchSingleData: (id) => {
    return sendRequest(
      API_REQUESTS.GET_DATA_BY_ID.method,
      `${API_REQUESTS.GET_DATA_BY_ID.path}`,
      { params: id }
    );
  },
};