import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useState } from 'react';
import { Loader } from '../../Loader/Loader';
import markerPin from "./../../../assets/location-pin.png";

const containerStyle = {
  height: "100%",
  borderRadius: "10px",
  boxShadow: "0px 0px 20px #8888",
};

const DetailsMap = ({ property }) => {
  const { mapCenter } = property
  const [map, setMap] = useState(null);
  const onLoad = useCallback((map) => setMap(map), []);
  const onUnmount = useCallback(() => setMap(null), []);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  const mapWidth = window.innerWidth < 769 ? `${window.innerWidth - 50}px` : "100%"

  if (!isLoaded) return <Loader />
  return (
    <GoogleMap
      mapContainerStyle={{ ...containerStyle, width: mapWidth }}
      center={mapCenter}
      onLoad={onLoad}
      onUnmount={onUnmount}
      zoom={20}
      options={{
        mapTypeId: "hybrid",
        streetViewControl: false,
        mapTypeControl: false,
        rotateControl: false,
      }}
    >
      <Marker
        key={property.address}
        position={mapCenter}
        onClick={() => { }}
        icon={{
          url: markerPin,
          scaledSize: new window.google.maps.Size(60, 60),
          origin: new window.google.maps.Point(0, 0),
        }}
      />
    </GoogleMap>
  )
}

export { DetailsMap };
