import React, { useEffect, useState } from 'react';
import { JsonView } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { useApiOperation } from '../../hooks/useApiOperation';
import { DataApi } from '../../lib/data.api';

const JsonEditor = ({ propertyId }) => {
  const [data, setData] = useState({})
  const { startApiOperation, terminateApiOperation, apiLoading, apiError } = useApiOperation()
  const preparedData = data && data.length > 0 ? data[0].data : {};

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      startApiOperation()
      const responseData = await DataApi.fetchSingleData({ id: propertyId })
      setData(responseData)
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation([error])
    }

  }

  if (apiError) {
    return <div>Error loading data</div>;
  }

  if (apiLoading) {
    return <div className='flex w-full justify-center'>Loading...</div>;
  }
  return (
    <JsonView
      data={preparedData}
      onEdit={() => { }}
    />
  );
};

export { JsonEditor };
