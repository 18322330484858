import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiOperation } from '../../hooks/useApiOperation';
import { ChemicalsApi } from '../../lib/chemicals.api';
import { HardwaresApi } from '../../lib/hardwares.api';
import { JobsApi } from '../../lib/jobs.api';
import { PropertiesApi } from '../../lib/properties.api';
import { NOTIFY_TYPE } from '../../utils/constants';
import { DropdownWithSelectionBox } from './DropdownWithSelectionBox';

const JobRequirements = ({ jobDetails, setJobDetails, user }) => {
  const { chemicals, mapCenter, hardwares, address } = jobDetails
  const date = new Date();
  const nav = useNavigate();
  const { startApiOperation, terminateApiOperation } = useApiOperation()
  const [hardware, setHardware] = useState([])
  const [chemical, setChemical] = useState([])

  useEffect(() => {
    fetchChemicals()
    fetchHardwares();
  }, []);


  const hardwareOptions = useMemo(() => {
    return hardware?.map((item) => ({
      id: item._id,
      name: item.name,
    }));
  }, [hardware]);

  const chemicalOptions = useMemo(() => {
    return chemical?.map((item) => ({
      id: item._id,
      name: item.chemicalName,
    }));
  }, [chemical]);


  const fetchHardwares = async () => {
    try {
      startApiOperation()
      const responseData = await HardwaresApi.fetchHardwares();
      setHardware(responseData);
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation("An error occurred while fetching hardwares");
    }
  };

  const fetchChemicals = async () => {
    try {
      startApiOperation()
      const responseData = await ChemicalsApi.fetchChemicals();
      setChemical(responseData);
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation(["An error occurred while fetching chemicals"]);
    }
  };


  const handleStartJob = async () => {
    try {
      startApiOperation()
      const propertyPayload = {
        address: address,
        mapCenter: mapCenter,
      }
      const createdProperty = await PropertiesApi.createProperty(propertyPayload)
      const payload = {
        address: createdProperty._id,
        chemicals: chemicals.length ? chemicals : null,
        hardwares: hardwares.length ? hardwares : null,
        mapCenter: mapCenter,
        ownerUserId: user.role === "user" ? user?._id : user?.ownerUserId,
        startTime: date,
        technician: user.role === "technician" ? user?._id : null,
      }
      const newJob = await JobsApi.createJob(payload)
      nav(`/jobInProgress/${newJob._id}?startTime=${newJob.startTime}`);
      terminateApiOperation(['New Job created successfully'], NOTIFY_TYPE.Success)
    } catch (error) {
      terminateApiOperation([error]);
    }
  };

  const StartJobButton = () => {

    if (!jobDetails.chemicals.length || !jobDetails.hardwares.length) return null

    return <div className="flex w-full justify-center py-2">
      <button
        className="bg-blue-600 text-white px-5 py-3 text-xl text-center rounded-md w-60 hover:bg-blue-800 transition-all tracking-wider"
        onClick={handleStartJob}
      >
        START JOB
      </button>
    </div>

  }

  return (
    <>
      <h1 className="text-3xl text-center py-6 font-medium text-blue-600 pb-4">
        Chemicals & Hardwares
      </h1>
      <div className="w-full flex flex-col md:flex-row p-5 gap-4">
        <DropdownWithSelectionBox
          dropdownOptions={hardwareOptions}
          setJobDetails={setJobDetails}
          placeholder="Choose Single or Multiple Hardwares"
          title="Selected Hardwares"
          classNames=""
          dropdownPosition="left"
          itemName="hardwares"
          jobDetails={jobDetails}
        />

        <DropdownWithSelectionBox
          dropdownOptions={chemicalOptions}
          setJobDetails={setJobDetails}
          placeholder="Choose Single or Multiple Chemicals"
          title="Selected Chemicals"
          classNames=""
          dropdownPosition="right"
          itemName="chemicals"
          jobDetails={jobDetails}
        />
      </div>
      <StartJobButton />
    </>
  )
}

export { JobRequirements };
