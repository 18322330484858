import React, { useEffect, useState } from 'react'
import { useApiOperation } from '../../hooks/useApiOperation';
import { NOTIFY_TYPE } from '../../utils/constants';
import { VersionsApi } from '../../lib/versions.api';
import { FileUpload } from './FileUpload/file-upload';
import { Loader } from '../Loader/Loader';
const UploadFile = ({ name, setName, fetchVersions }) => {
  const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation()
  const [enabled, setEnabled] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const disabledButtonStyles = enabled
    ? 'bg-blue-600 hover:bg-blue-700 cursor-pointer'
    : 'bg-gray-400 cursor-not-allowed';

    
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', uploadedFiles[0]);
    try {
      startApiOperation()
      await VersionsApi.addVersion(formData)
      setName('');
      setUploadedFiles([]);
      terminateApiOperation(['New version added successfully'], NOTIFY_TYPE.Success)
      fetchVersions()
    } catch (error) {
      terminateApiOperation([error])
    }
  };

  useEffect(() => {
    setEnabled(name.length > 0 && uploadedFiles.length > 0);
  }, [name, uploadedFiles.length]);

  if (apiLoading) return <Loader />
  return (
    <div className="h-full w-4/5 p-4 justify-around flex flex-col">
      <FileUpload setFileSelect={setUploadedFiles} files={uploadedFiles} />
      <div className="w-full flex justify-center items-end h-full">
        <button
          className={`px-4 py-2 rounded-md text-white font-semibold ${disabledButtonStyles}`}
          onClick={handleSubmit}
          disabled={!enabled}
        >
          Upload to Server
        </button>
      </div>
    </div>
  )
}

export { UploadFile }