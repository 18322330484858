import { useEffect, useState } from 'react';
import { useApiOperation } from '../../hooks/useApiOperation';
import { UpdatesApi } from '../../lib/updates.api';
import { VersionsApi } from '../../lib/versions.api';
import { NOTIFY_TYPE } from '../../utils/constants';
import { DropdownMenuHC } from '../Menus/DropdownHC';

const HardwaresAndVersions = ({ hardwares }) => {
  const [selectedHardwares, setSelectedHardwares] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState([]);
  const { startApiOperation, terminateApiOperation } = useApiOperation()
  const [versions, setVersions] = useState([])
  const isUploadEnabled = selectedHardwares.length > 0 && selectedVersion.length > 0;
  const disabledButtonStyles = isUploadEnabled ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'
  const hardwaresList = hardwares?.map((item) => ({ name: item.hwid, id: item.hwid }));
  const versionsList = versions?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item) => ({ name: item.name }));
  const selectedVersionForUpdate = versions && versions?.find((item) => item.name === selectedVersion);

  const handleUploadToServer = async () => {
    try {
      startApiOperation()
      const payload = {
        versionId: selectedVersionForUpdate?._id || '',
        hardwares: selectedHardwares
      }
      await UpdatesApi.publishUpdate(payload)
      setSelectedHardwares([])
      setSelectedVersion([])
      terminateApiOperation(['Updates Deployed Successfully'], NOTIFY_TYPE.Success)
    } catch (error) {
      terminateApiOperation([error])
    }
  };

  const fetchVersions = async () => {
    try {
      startApiOperation()
      const versions = await VersionsApi.getVersions()
      setVersions(versions)
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation([error])
    }
  }

  const DisplaySelectedHardwares = () => {
    return (
      <div className='flex flex-col gap-3 w-full md:w-2/5'>
        <span className="w-76 min-w-[20rem]">
          <DropdownMenuHC
            dropdownOptions={hardwaresList}
            selector={selectedHardwares}
            setSelector={setSelectedHardwares}
            arrowDirectionUp={false}
            type="checkbox"
            placeholder='Choose Single or Multiple Hardwares'
          />
        </span>
        <div className="h-full w-full min-h-[20rem] border rounded-xl py-4 divide-y">
          <h1 className="pb-2 text-center text-gray-900 font-semibold text-lg">Selected Hardwares</h1>
          <div className="p-2 flex flex-col gap-2 overflow-y-auto max-h-[95%]">
            {selectedHardwares.map((item, index) => (
              <div key={index} className="text-md text-blue-500 bg-gray-50 shadow-md border border-gray-300 rounded-md p-2">
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  const DisplaySelectedVersion = () => {
    const version = selectedVersion.length > 0 ?
      <div className="text-md text-blue-500 bg-gray-50 shadow-md border border-gray-300 rounded-md p-2">
        {selectedVersion}
      </div> : ''

    return (
      <div className='flex flex-col gap-3 w-full md:w-2/5 items-end'>
        <span className="w-72">
          <DropdownMenuHC
            dropdownOptions={versionsList}
            selector={selectedVersion}
            setSelector={setSelectedVersion}
            arrowDirectionUp={false}
            placeholder='Choose a Version'
            dropdownPosition='right'
          />
        </span>
        <div className="h-full min-h-[20rem] w-full border rounded-xl py-4 divide-y">
          <h1 className="pb-2 text-center text-gray-900 font-semibold text-lg">Selected Version</h1>
          <div className="p-2 flex flex-col gap-2 ">
            {version}
          </div>
        </div>
      </div>
    )
  }

  const PostUpdatesButton = () => {
    return (
      <div className="w-full flex justify-center items-end h-20">
        <button
          className={`px-4 py-2 rounded-md text-white font-semibold ${disabledButtonStyles}`}
          onClick={handleUploadToServer}
          disabled={!isUploadEnabled}
        >
          Post Updates to Server
        </button>
      </div>
    )
  }

  useEffect(() => {
    fetchVersions()
  }, [])

  return (
    <div className="w-full gap-5 justify-center py-8 flex flex-col h-auto md:h-4/5">
      <div className='flex w-full h-full justify-between flex-col md:flex-row gap-5 flex-wrap px-0 md:px-10'>
        <DisplaySelectedHardwares />
        <DisplaySelectedVersion />
      </div>
      <PostUpdatesButton />
    </div>
  )
}

export { HardwaresAndVersions };
