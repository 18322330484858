import { HTTP_METHODS } from "../utils/constants";
import { sendRequest } from "../utils/request-service";

const API_REQUESTS = {
  PUBLISH_UPDATE: {
    path: '/update/newUpdate',
    method: HTTP_METHODS.POST,
  },
}

export const UpdatesApi = {
  publishUpdate: (data) => {
    return sendRequest(
      API_REQUESTS.PUBLISH_UPDATE.method,
      `${API_REQUESTS.PUBLISH_UPDATE.path}`,
      data
    );
  },
}
