import {
  ArrowLeftIcon,
  ArrowPathIcon,
  CheckIcon,
} from "@heroicons/react/20/solid";
import dayjs from "dayjs";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "../Loader/Loader";

const JobsTimeline = ({ jobs, loading }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleBack = () => {
    navigate(`/mapview/properties?${searchParams}`, { replace: true });
  };

  const handleJobDetails = (jobId) => {
    navigate(`/mapview/job-detail/${jobId}`, { replace: true });
  };

  const ListFilteredJobs = () => {
    if (!jobs.length) return (<p className="text-gray-500 text-center mt-4">No jobs found with the selected filters.</p>)
    return jobs.map((job, idx) => (
      <li key={job._id || idx}>
        <div className="relative pb-8">
          {idx !== jobs.length - 1 && (
            <span
              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />
          )}
          <div className="relative flex items-center space-x-3">
            <RenderJobStatusIcon job={job} />
            <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
              <div>
                <button
                  onClick={() => handleJobDetails(job._id)}
                  className="font-medium text-left text-gray-900 hover:text-gray-500"
                >
                  <RenderJobButtonText job={job} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    ))
  }


  const RenderJobStatusIcon = ({ job }) => {
    const Icon = ({ job }) => {
      if (job.active === false) {
        return <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
      } else {
        return <ArrowPathIcon className="h-5 w-5 text-white" aria-hidden="true" />
      }
    }

    return <div>
      <span
        className={`mt-2 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white 
        ${job.active === false ? "bg-blue-500" : "bg-purple-500"}`}
      >
        <Icon job={job} />
      </span>
    </div>
  }

  const RenderJobButtonText = ({ job }) => {
    if (job.active === false) {
      return (<> Job completed at {dayjs(job.endTime).format("DD MMM")}</>)
    } else {
      return (<>Job started at{" "}<span className="text-gray-600">{dayjs(job.startTime).format("DD MMM")}</span>
      </>)
    }
  }


  if (loading) return <Loader />

  return (
    <div className="mt-6 flow-root">
      <div className="px-4 py-3 sm:rounded-lg sm:px-6">
        <div className="mt-[-20px]">
          <button
            onClick={() => handleBack()}
            type="button"
            aria-label="Go back"
            className="text-blue-700 font-medium rounded-full hover:bg-blue-100 bg-blue-50 text-sm p-2.5 inline-flex items-center"
          >
            <ArrowLeftIcon className="w-6 h-6" />
          </button>
        </div>
        <ul className="mt-2">
          <li>
            <h2
              id="timeline-title"
              className="text-lg font-medium text-gray-900 mt-2"
            >
              {jobs[0]?.address?.address || "Job Location"}
            </h2>
          </li>
          <ListFilteredJobs />
        </ul>
      </div>
    </div>
  );
}


export { JobsTimeline };
