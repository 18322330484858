import { useEffect, useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { HardwaresAndVersions } from '../components/ota-updates/hardwares-and-versions';
import { useApiOperation } from '../hooks/useApiOperation';
import { HardwaresApi } from '../lib/hardwares.api';

const OtaUpdates = () => {
  const { startApiOperation, terminateApiOperation } = useApiOperation()
  const [hardwares, setHardwares] = useState([]);

  const fetchHardwares = async () => {
    try {
      startApiOperation()
      const responseData = await HardwaresApi.fetchHardwares();
      setHardwares(responseData);
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation([error])
      console.error("Error fetching hardwares:", error);
    }
  };

  useEffect(() => {
    fetchHardwares();
  }, []);

  return (
    <DashboardLayout>
      <div className="w-full flex flex-wrap justify-start items-center p-3 flex-col h-full md:h-screen divide-y">
        <h1 className='text-2xl md:text-3xl font-medium text-gray-800 pb-4'>Over The Air Updates</h1>
        <HardwaresAndVersions hardwares={hardwares} />
      </div>
    </DashboardLayout>
  );
};

export default OtaUpdates;
