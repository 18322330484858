import {
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";

export const Table = ({
  tabledata,
  headers,
  enableSorting,
  handleSorting,
  name,
  isEdit,
  ...props
}) => {
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [openPopover, setOpenPopover] = useState(null); // Tracks which row's popover is open
  const popoverRefs = useRef({}); // To handle click outside for multiple popovers

  // Handle row selection (if needed)
  function handleSelection(bloc) {
    if (!props.selected.includes(bloc.key)) {
      props.setSelected((prev) => [...prev, bloc.key]);
    } else {
      let temp_arr = [...props.selected];
      temp_arr.splice(temp_arr.indexOf(bloc.key), 1);
      props.setSelected(temp_arr);
    }
  }

  // Handle select all rows (if needed)
  function handleSelectionAll() {
    if (props.allSelected === null) {
      props.setSelected([]);
    } else {
      props.setAllSelected(null);
    }
  }

  // Check if all rows are selected (if needed)
  function checkAllChecked() {
    return props.allSelected !== null;
  }

  // Handle sorting (if enabled)
  const handleSortingChange = (accessor) => {
    const newSortOrder =
      sortField === accessor && sortOrder === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setSortOrder(newSortOrder);
    handleSorting && handleSorting(accessor, newSortOrder);
  };

  // Toggle the popover for a specific row
  const togglePopover = (key) => {
    setOpenPopover((prev) => (prev === key ? null : key));
  };

  // Close popover when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(popoverRefs.current).forEach((key) => {
        if (
          popoverRefs.current[key] &&
          !popoverRefs.current[key].contains(event.target)
        ) {
          setOpenPopover((prev) => (prev === key ? null : prev));
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 w-full">
      <div className="flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-0">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  {/* Uncomment if you have a selection column */}
                  {/* {props.isMeasurementPage && (
                    <th>
                      <input
                        type="checkbox"
                        className="w-5 h-5 rounded-md cursor-pointer text-green-600 bg-white border-gray-300 focus:ring-transparent float-left"
                        onChange={handleSelectionAll}
                        checked={checkAllChecked()}
                      />
                    </th>
                  )} */}
                  {headers &&
                    headers.map((each, idx) => (
                      <th
                        key={idx}
                        scope="col"
                        className="pl-4 pr-5 min-w-min w-auto py-2 text-normal font-semibold text-gray-500 sm:pl-0"
                      >
                        <div className="flex justify-start items-center gap-2 ml-5">
                          {each.name === "view"
                            ? "Details"
                            : each.name === "delete"
                              ? "Delete"
                              : each.name}
                          {/* Uncomment if you have sorting enabled
                          {enableSorting && each.name !== 'view' && each.ref !== null ?
                            <RxCaretSort size={20} className="text-gray-500 hover:text-gray-700 text-sm cursor-pointer" onClick={() => handleSortingChange(each.ref)} />
                            : null
                          } */}
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className="divide-y w-96">
                {tabledata &&
                  tabledata.map((each, index) => {
                    // Use a unique key; fallback to index if not present
                    const rowKey = each.key || index;

                    return (
                      <tr key={rowKey}>
                        {Object.keys(tabledata[0])
                          .filter((item) => item !== "key")
                          .map((item, indx) => {
                            if (
                              item === "Chemicals" &&
                              each[item] &&
                              each[item].props &&
                              each[item].props.children
                            ) {
                              // Extract chemical names from the React element
                              let chemicalNames = [];

                              if (Array.isArray(each[item].props.children)) {
                                chemicalNames = each[item].props.children.map(
                                  (child) => child.props.children
                                );
                              } else {
                                chemicalNames = [each[item].props.children];
                              }

                              const isExpanded = openPopover === rowKey;
                              const hasMore = chemicalNames.length > 1;

                              return (
                                <td
                                  className="min-w-min w-auto pr-5 py-4 text-sm text-black pl-5 relative"
                                  key={indx}
                                  ref={(el) =>
                                    (popoverRefs.current[rowKey] = el)
                                  }
                                >
                                  <div className="flex justify-between items-center">
                                    {/* Chemical Name */}
                                    <span className="flex-1">
                                      {chemicalNames[0]}
                                    </span>

                                    {/* Chevron Icon */}
                                    {hasMore && (
                                      <span
                                        className="text-blue-500 cursor-pointer flex-shrink-0 ml-2"
                                        onClick={() => togglePopover(rowKey)}
                                        aria-label={
                                          isExpanded
                                            ? "Show less chemicals"
                                            : "Show more chemicals"
                                        }
                                      >
                                        {/* {isExpanded ? (
                                          <ChevronUpIcon className="h-4 w-4" />
                                        ) : (
                                          <ChevronDownIcon className="h-4 w-4" />
                                        )} */}
                                        {!openPopover && (
                                          <ChevronDownIcon className="h-4 w-4" />
                                        )}
                                      </span>
                                    )}
                                  </div>

                                  {/* Popover Content */}
                                  {isExpanded && hasMore && (
                                    <div className="absolute left-[50%] top-0 mt-1 w-[95%] md:w-[93%] lg:w-[95%] xl:w-[85%] pl-2 pr-2 bg-white border border-gray-300 rounded shadow-lg z-10 p-2 h-auto">
                                      {/* Close Button */}
                                      <button
                                        className="absolute top-1 right-1 text-gray-500 hover:text-gray-700 focus:outline-none"
                                        onClick={() => setOpenPopover(null)}
                                        aria-label="Close popover"
                                      >
                                        <XMarkIcon className="text-red-600 font-extrabold h-4 w-4" />
                                      </button>
                                      <ul className="list-disc list-inside text-sm text-gray-700 py-3">
                                        {chemicalNames.map((chem, chemIdx) => (
                                          <li key={chemIdx}>{chem}</li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                </td>
                              );
                            } else {
                              // For other columns, render normally
                              return (
                                <td
                                  className="min-w-min w-auto pr-5 py-4 text-sm text-black pl-5"
                                  key={indx}
                                >
                                  {each[item] && each[item] !== "N/A"
                                    ? each[item]
                                    : each[item] === false
                                      ? null
                                      : "-"}
                                </td>
                              );
                            }
                          })}
                      </tr>
                    );
                  })}
                {isEdit && (
                  <tr>
                    <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                    <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                    <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                    <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                    <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                  </tr>
                )}
              </tbody>
            </table>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};
