import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export const PopupBox = ({ modal, handleClose, title, children }) => {
    return (
        <Dialog open={modal} as="div" className="fixed z-10 inset-0 overflow-y-auto " onClose={handleClose}>
            <DialogBackdrop className="fixed inset-0 bg-black/40" />
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center">
                    <DialogPanel
                        transition
                        className="w-full max-w-lg h-full rounded-xl bg-white/5 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                    >
                        <div className="inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl border-gray-200 transform transition-all sm:align-middle sm:max-w-sm md:max-w-md lg:max-w-lg sm:w-full sm:p-6 ">
                            <div className="flex items-center justify-between">
                                <DialogTitle as="h3" className="text-xl leading-6 font-bold text-gray-900">
                                    {title}
                                </DialogTitle>
                                <XMarkIcon
                                    className="w-6 h-6 cursor-pointer"
                                    aria-hidden="true"
                                    onClick={() => handleClose()}
                                />
                            </div>
                            {children}
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
};
