
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/24/outline';


export const DeletePopup = ({ onDelete, label, desc, open, setOpen }) => {
    return (
        <Dialog open={open} as="div" className="fixed z-10 inset-0 overflow-y-auto " onClose={setOpen}>
            <DialogBackdrop className="fixed inset-0 bg-black/40" />
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center">
                    <DialogPanel
                        transition
                        className="w-full max-w-md h-full rounded-xl bg-white/5 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                    >
                        <div className="inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full sm:p-6">
                            <div className="mx-auto flex items-center justify-center h-32 w-32 rounded-full bg-red-100">
                                <TrashIcon className="w-16 h-16 text-red-600" />
                            </div>

                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-xl leading-6 font-semibold text-gray-900">
                                    {label}
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">{desc}</p>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 flex gap-x-5">
                                <button
                                    type="button"
                                    className="px-5 py-2 border border-[#DFE6E2] rounded-md bg-white hover:text-blue-500 w-full text-sm"
                                    onClick={() => setOpen(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                                    onClick={onDelete}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
};
