import { useLoadScript } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../components/DashboardLayout";
import GoogleAutocompleteInput from "../../components/Start/GoogleAutocompleteInput";
import { JobRequirements } from "../../components/Start/JobRequirements";
import { useApiOperation } from "../../hooks/useApiOperation";
import { useUser } from "../../hooks/useAuth";
import { JobsApi } from "../../lib/jobs.api";

// Keeping libraries array as a const outside component due to performance warning in console
const libraries = ["places"];


const initialState = {
  chemicals: [],
  hardwares: [],
  address: '',
  mapCenter: {
    lat: 0,
    lng: 0
  }
}

const Start = () => {
  const nav = useNavigate();
  const { startApiOperation, terminateApiOperation } = useApiOperation()
  const [jobDetails, setJobDetails] = useState(initialState)
  const [jobsData, setJobsData] = useState([])
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  const { data: userData } = useUser();


  useEffect(() => {
    searchJobs()
  }, [userData]);

  useEffect(() => {
    if (!jobsData?.jobs?.length) return;
    const { jobs } = jobsData;
    const currentOpenJob = jobs[0];
    nav(`/jobInProgress/${currentOpenJob._id}?startTime=${currentOpenJob.startTime}`);
  }, [userData, jobsData]);


  const handleJobDetailsChange = (name, value) => {
    setJobDetails((prevJobDetails) => ({
      ...prevJobDetails,
      [name]: value,
    }));
  };


  const searchJobs = async () => {
    try {
      if (!userData) return
      const { role, _id } = userData?.user
      startApiOperation()
      const requestBody = {
        page: 1,
        search: "",
        technicianId: role === "technician" ? _id : null,
        role: role,
        active: true,
      }
      const responseData = await JobsApi.searchJobs(requestBody)
      setJobsData(responseData)
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation(["An error occurred while searching for jobs."]);
    }
  };


  const DisplayJobRequirements = () => {
    if (!jobDetails.address.length) return

    return <JobRequirements jobDetails={jobDetails} setJobDetails={handleJobDetailsChange} user={userData?.user} />
  }

  const AddressBar = () => {
    if (!isLoaded) return
    return (
      <div className="mt-10 w-full justify-center flex flex-col items-center">
        <GoogleAutocompleteInput setJobDetails={handleJobDetailsChange} address={jobDetails.address} />
      </div>
    )
  }

  return (
    <DashboardLayout>
      <AddressBar />
      <DisplayJobRequirements />
    </DashboardLayout>
  );
}


export default Start