import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useCallback, useEffect, useState } from "react";
import { mergeClasses } from "../../utils/helpers";

const DropdownMenuHC = ({
  dropdownOptions,
  label,
  selector = [],
  setSelector,
  arrowDirectionUp,
  dropdownPosition,
  type = "default",
  placeholder = "Choose an Option",
  itemName = "Hardwares ",
  classNames = "",
  onSelect = () => { },
  onReset = () => { },
}) => {
  const [recentSelection, setRecentSelection] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const defaultButtonStyles = `inline-flex w-full justify-between text-sm text-gray-900 px-4 py-2.5 cursor-pointer gap-6`;
  const menuButtonClasses = mergeClasses(defaultButtonStyles, classNames);

  const resetSelections = () => {
    setRecentSelection("");
  };

  // Handle reset externally if onReset is provided
  useEffect(() => {
    if (typeof onReset === "function") {
      onReset(resetSelections);
    }
  }, [onReset]);

  const allSelected = (selector || []).length === dropdownOptions.length;

  const handleDropdownClick = useCallback((e) => {
    e.preventDefault();
    setIsDropdownOpen((prev) => !prev);
  }, []);

  async function handleClick(valName, e) {
    e.preventDefault();
    e.stopPropagation();
    setRecentSelection(valName);

    const selectedOption = dropdownOptions.find(
      (option) => option.name === valName
    );
    const selectedId = selectedOption?.id;

    if (type === "checkbox") {
      if (valName === "Select All") {
        if (allSelected) {
          setSelector([]);
          onSelect([]);
        } else {
          const allIds = dropdownOptions.map((option) => option.id || option);
          setSelector(allIds);
          //TODO: onSelect(allIds);
        }
      } else {
        const isSelected = selector.includes(selectedId);
        if (isSelected) {
          const updatedSelector = selector.filter((id) => id !== selectedId);
          setSelector(updatedSelector);
          onSelect(updatedSelector);
        } else {
          const updatedSelector = [...selector, selectedId];
          setSelector(updatedSelector);
          onSelect && onSelect(updatedSelector);
        }
      }
    } else {
      setSelector(valName);
    }

    // Close dropdown after selection
    setIsDropdownOpen(false);
  }

  // Updated useEffect to manage recentSelection without dateRange
  useEffect(() => {
    if (selector.length > 0) {
      if (type === "checkbox") {
        setRecentSelection(`${itemName} Selected`);
      } else {
        setRecentSelection(selector);
      }
    } else {
      setRecentSelection(placeholder);
    }
  }, [selector, placeholder, type, itemName]);

  function isSelected(valName) {
    return selector.includes(valName);
  }

  const CheckBoxTypeMenuItem = () => {
    if (type !== "checkbox") return null;
    return (
      <Menu.Item>
        {({ active }) => (
          <button
            className="text-gray-900 stroke-gray-400 group flex w-full items-center rounded-md px-2 py-2 text-sm"
            onClick={(e) => handleClick("Select All", e)}
          >
            <input
              type="checkbox"
              className="mr-2"
              checked={allSelected}
              readOnly
            />
            Select All
          </button>
        )}
      </Menu.Item>
    );
  };

  const SimpleMenuItems = () => {
    return dropdownOptions.map((val, index) => (
      <Menu.Item key={index}>
        {({ active }) => (
          <button
            className={`${isSelected(val.id)
              ? "bg-blue-50 text-blue-500 hover:stroke-blue-500 hover:fill-blue-500"
              : "text-gray-900 stroke-gray-400"
              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
            onClick={(e) => handleClick(val.name, e)}
          >
            {type === "checkbox" && (
              <input
                type="checkbox"
                className="mr-2"
                checked={isSelected(val.id)}
                readOnly
              />
            )}
            {val.name}
          </button>
        )}
      </Menu.Item>
    ));
  };

  const RenderDropdownMenu = () => {
    return (
      <Menu as="div" className="relative inline-block">
        <div className="flex w-full justify-between items-center rounded-md bg-white text-sm text-gray-900 border border-gray-300">
          <Menu.Button
            className={menuButtonClasses}
            onClick={handleDropdownClick}
            style={{ width: "100%" }}
          >
            <div className="text-[14px] flex-grow-1 whitespace-nowrap flex w-full justify-between">
              <span>
                {type === "checkbox" && selector.length > 0
                  ? `${itemName} Selected`
                  : recentSelection
                    ? recentSelection
                    : selector.length > 0
                      ? selector
                      : placeholder}
              </span>
              <div className="flex">
                {isDropdownOpen ? (
                  <ChevronUpIcon
                    className="ml-4 -mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronDownIcon
                    className="ml-4 -mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          show={isDropdownOpen}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute z-50 w-full min-w-[150px] divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-72 overflow-y-auto ${arrowDirectionUp ? "bottom-12" : "mt-2"
              }`}
          >
            <div className="px-1 py-1">
              <CheckBoxTypeMenuItem />
              <SimpleMenuItems />
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  return (
    <div className="w-full flex">
      <div className={`relative w-full flex text-left ${dropdownPosition === "right" ? "justify-end " : "justify-start"}`}>
        <div className={`flex max-w-fit flex-col gap-1`}>
          {label}
          <RenderDropdownMenu />
        </div>
      </div>
    </div>
  );
}



export { DropdownMenuHC };
