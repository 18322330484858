import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import OnlyAdmin from "./components/OnlyAdmin.jsx";
import Protected from "./components/Protected";
import AcceptInvitation from "./pages/AcceptInvitation";
import AdminMapView from "./pages/admin/page.jsx";
import AdminDashboard from "./pages/adminDashboard.jsx";
import Chemicals from "./pages/chemical/chemicals";
import AddClient from "./pages/client/add-client/page.jsx";
import ClientDetails from "./pages/client/client-details/page.jsx";
import EditClientInfo from "./pages/client/edit-client/page.jsx";
import ClientsPage from "./pages/client/page.jsx";
import Dashboard from "./pages/dashboard/dashboard";
import Hardware from "./pages/hardware/hardware";
import JobDetails from "./pages/job-details/[id].jsx";
import Job from "./pages/job-in-progress/job.jsx";
import Jobs from "./pages/jobs/jobs.jsx";
import JobsOnProperty from "./pages/map-view/(filtered-pages)/jobs-on-property/page.jsx";
import MapView from "./pages/map-view/(filtered-pages)/properties/page.jsx";
import JobDetail from "./pages/map-view/job-detail/page.jsx";
import MyAccount from "./pages/myaccount";
import JobCompleted from "./pages/new-job-details/JobCompleted.jsx";
import NoPage from "./pages/nopage";
import OtaUpdates from "./pages/OtaUpdates.jsx";
import Profile from "./pages/Profile";
import Signin from "./pages/signin";
import Start from "./pages/start-job/start.jsx";
import Technicians from "./pages/Technicians/Technicians.jsx";
import Tutorials from "./pages/Tutorials.jsx";
import Versions from "./pages/Versions.jsx";

const App = () => {

  const retrieveUserFromLocalStorage = () => {
    const user = localStorage.token && JSON.parse(atob(localStorage.token.split('.')[1]));
    return user?.role
  }

  const redirectToDashboard = (path) => {
    if (retrieveUserFromLocalStorage() === "technician") {
      return <Route path={path} element={<Navigate to="/dashboard" />} />
    }

    if (retrieveUserFromLocalStorage() === "admin") {
      return <Route path={path} element={<Navigate to="/admin/dashboard" />} />
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={localStorage.token
          ? (<Navigate replace to='/dashboard' />)
          : (<Navigate replace to='/signin' />)} />
        <Route path="signin" element={localStorage.token ? retrieveUserFromLocalStorage() === 'admin'
          ? (<Navigate replace to={'/admin/dashboard'} />)
          : (<Navigate replace to={'/dashboard'} />)
          : (<Signin />)} />
        <Route path="mapview/properties" element={<Protected isLoggedIn={localStorage.token}><MapView /></Protected>} />
        <Route path="mapview/jobs-on-property/:id" element={<Protected isLoggedIn={localStorage.token}><JobsOnProperty /></Protected>} />
        <Route path="mapview/job-detail/:id" element={<Protected isLoggedIn={localStorage.token}><JobDetail /></Protected>} />
        <Route path="/adminMap" element={<OnlyAdmin role={retrieveUserFromLocalStorage()}><AdminMapView /></OnlyAdmin >} />
        <Route path="myaccount" element={<Protected isLoggedIn={localStorage.token}><MyAccount /></Protected>} />
        <Route path="profile" element={<Protected isLoggedIn={localStorage.token}><Profile /></Protected>} />
        <Route path="technicians" element={<Protected isLoggedIn={localStorage.token}><Technicians /></Protected>} />
        <Route path="/jobs" element={<Protected isLoggedIn={localStorage.token}><Jobs /></Protected>} />
        {redirectToDashboard("chemicals")}
        <Route path="chemicals" element={<Protected isLoggedIn={localStorage.token}><Chemicals /></Protected>} />
        <Route path="dashboard" element={<Protected isLoggedIn={localStorage.token}><Dashboard /></Protected>} />
        <Route path="/admin/dashboard" element={<OnlyAdmin role={retrieveUserFromLocalStorage()}><AdminDashboard /></OnlyAdmin>} />
        <Route path="/admin/updates" element={<OnlyAdmin role={retrieveUserFromLocalStorage()}><OtaUpdates /></OnlyAdmin>} />
        <Route path="/admin/versions" element={<OnlyAdmin role={retrieveUserFromLocalStorage()}><Versions /></OnlyAdmin>} />
        <Route path="/start" element={<Protected isLoggedIn={localStorage.token}><Start /></Protected>} />
        <Route path="/clients" element={<Protected isLoggedIn={localStorage.token}><ClientsPage /></Protected>} />
        <Route path="/clients/add-new" element={<Protected isLoggedIn={localStorage.token}><AddClient /></Protected>} />
        <Route path="/client/:id" element={<Protected isLoggedIn={localStorage.token}><EditClientInfo /></Protected>} />
        <Route path="/client-details/:id" element={<Protected isLoggedIn={localStorage.token}><ClientDetails /></Protected>} />
        <Route path="jobInProgress/:id" element={<Protected isLoggedIn={localStorage.token}><Job /></Protected>} />
        <Route path="/jobDetails/:id" element={<Protected isLoggedIn={localStorage.token}><JobCompleted /></Protected>} />
        <Route path="/job-details/:id" element={<Protected isLoggedIn={localStorage.token}><JobDetails /></Protected>} />
        <Route path="/invitation/accept-invitation/:email" element={<AcceptInvitation />} />
        {redirectToDashboard("hardware")}
        <Route path="hardware" element={<Protected isLoggedIn={localStorage.token}><Hardware /></Protected>} />
        <Route path="*" element={<NoPage />} />
        <Route path="/tutorials" element={<Protected isLoggedIn={localStorage.token}><Tutorials /></Protected>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
