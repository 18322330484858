import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setNotifications } from '../../features/notificationsSlice'
import { useApiOperation } from '../../hooks/useApiOperation'
import { ChemicalsApi } from '../../lib/chemicals.api'
import { NOTIFY_TYPE } from '../../utils/constants'
import { PrimaryButton } from '../Button/PrimaryButton'
import { PopupBox } from '../Popups/PopupBox'
import { AddEditForm } from './addEditForm'


const AddChemical = ({ refetch, measurementUnit }) => {
  const initialChemicalState = {
    chemicalName: '',
    description: '',
    concentration: 0,
    measurement: measurementUnit
  }
  const dispatch = useDispatch()
  const { startApiOperation, terminateApiOperation } = useApiOperation()
  const [openModal, setOpenModal] = useState(false)
  const [chemical, setChemical] = useState(initialChemicalState);
  const { chemicalName, concentration, description } = chemical


  const validateInputs = () => {
    const errors = [];

    if (!chemicalName) errors.push('Chemical name is required');
    if (!description) errors.push('Chemical description is required');
    if (!concentration) errors.push('Concentration is required');
    return errors;
  };

  const handleSubmit = async () => {
    try {
      startApiOperation()
      const errors = validateInputs();
      if (errors.length) return dispatch(setNotifications({ type: NOTIFY_TYPE.Error, messages: errors }));
      await ChemicalsApi.addChemical(chemical);
      setOpenModal(false);
      refetch();
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation([
        'Something went wrong while creating new chemical. Please try again later',
      ]);
    }
  }

  return (
    <>
      <div className='flex justify-end mr-4 mt-2'>
        <PrimaryButton
          label='Add Chemical'
          onClickEvent={() => setOpenModal(!openModal)}
          displayPlus
        />
      </div>
      <PopupBox modal={openModal} handleClose={() => setOpenModal(false)} title='Add new chemical' >
        <AddEditForm
          chemical={chemical}
          setChemical={setChemical}
          onSubmit={handleSubmit}
        />
      </PopupBox>
    </>
  )
}

export { AddChemical }
