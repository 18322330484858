import React from 'react';
import { Field } from '../../../utils/shared/elements';
import { DetailsMap } from './details-map';

const Details = ({ clientDetails }) => {

  const customerDetails = [
    { label: 'Name', text: clientDetails.clientName },
    { label: 'Email Address', text: clientDetails.email }
  ];

  const PropertyDetails = [
    { label: 'Address', text: clientDetails.property.address },
    { label: 'Area', text: clientDetails.property.squareFeet }
  ];

  const RenderFeatureFields = ({ type }) => {
    return (type === 'property' ?
      PropertyDetails.map((value, index) => (
        <Field label={value.label} text={value.text} key={index} />
      )) :
      customerDetails.map((value, index) => (
        <Field label={value.label} text={value.text} key={index} />
      ))
    )
  };


  const RenderClientDetails = () => {
    return (
      <div className='rounded-xl  border p-6 h-fit'>
        <h1 className='text-lg font-semibold text-gray-800 mb-2'>Client Details</h1>
        <div className="grid grid-cols-2 gap-x-10 gap-y-6">
          <RenderFeatureFields type='client' />
        </div>
      </div>
    )
  }

  const RenderPropertyDetails = () => {
    return (
      <div className='rounded-xl  border p-6 h-fit'>
        <h1 className='text-lg font-semibold text-gray-800 mb-2'>Property Details</h1>
        <div className="grid grid-cols-2 gap-x-10 gap-y-6">
          <RenderFeatureFields type='property' />
        </div>
      </div>
    )
  }


  return (
    <div className='py-6 w-full'>
      <div className='flex flex-col sm:flex-row flex-wrap justify-between p-1 sm:p-4 rounded-xl gap-4 '>
        <div className='w-full lg:w-[47%] my-3 gap-4 flex flex-col justify-between'>
          <RenderClientDetails />
          <RenderPropertyDetails />
        </div>
        <div className='w-full lg:w-[47%] p-3'>
          <div className='border rounded-xl h-96'>
            <DetailsMap property={clientDetails.property} />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Details };

