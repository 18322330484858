import { useParams, useSearchParams } from "react-router-dom";
import DashboardLayout from "../../components/DashboardLayout";
import { JobInProgress } from "../../components/Start/Job/JobInProgress";

const Job = () => {
	const { id } = useParams()
	const [searchParams, setSearchParams] = useSearchParams()
	const startTime = searchParams.get('startTime')

	return (
		<DashboardLayout>
			<JobInProgress jobId={id} startTime={startTime} />
		</DashboardLayout>
	)
}

export default Job 
