import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useState } from 'react';
import markerPin from "./../../../assets/pin1.png";

const containerStyle = {
  height: "600px",
  borderRadius: "10px",
  boxShadow: "0px 0px 20px #8888",
};

const center = {
  lat: 42.06012414889974,
  lng: -91.63162586405998,
};


const Map = ({ data, setPropertyId, propertyId }) => {
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(8);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const onLoad = useCallback((map) => setMap(map), []);
  const onUnmount = useCallback(() => setMap(null), []);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const mapWidth = window.innerWidth < 769 ? `${window.innerWidth - 40}px` : "100%"

  useEffect(() => {
    if (!propertyId) {
      setSelectedAddress(null);
      return;
    }
    let selected
    if (data?.length) {
      selected = data?.find((property) => property?._id === propertyId);
    } else {
      selected = data.address
    }
    if (selected) {
      setSelectedAddress(selected);
      setZoom(20);
      map?.panTo(selected.mapCenter ?? selected.address.mapCenter);
    }
  }, [propertyId, data, map]);

  const onMarkerClick = (jobAddressId) => {
    setPropertyId(jobAddressId);
  };


  const DisplayAddress = () => {
    if (!selectedAddress && data.length > 0) {
      return data.map((jobAddress) => (
        <Marker
          key={jobAddress._id}
          position={jobAddress.mapCenter ?? jobAddress.address.mapCenter}
          onClick={() => onMarkerClick(jobAddress._id)}
          icon={{
            url: markerPin,
            scaledSize: new window.google.maps.Size(20, 20),
            origin: new window.google.maps.Point(0, 0),
          }}
        />
      ));
    }
    return (
      <Marker
        key={selectedAddress?._id}
        position={selectedAddress?.mapCenter ?? selectedAddress?.address?.mapCenter}
        onClick={() => onMarkerClick(selectedAddress?._id)}
        icon={{
          url: markerPin,
          scaledSize: new window.google.maps.Size(30, 30),
          origin: new window.google.maps.Point(0, 0),
        }}
      />
    );
  };


  if (!isLoaded) return null
  return (
    <GoogleMap
      mapContainerStyle={{ ...containerStyle, width: mapWidth }}
      center={data[0]?.mapCenter ?? center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      zoom={zoom}
      options={{
        mapTypeId: "hybrid",
        streetViewControl: false,
        mapTypeControl: false,
        rotateControl: false,
      }}
    >
      <DisplayAddress />
    </GoogleMap>
  )
}

export { Map };

