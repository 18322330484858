import React, { useMemo } from 'react';
import { Table } from '../../utils/shared/table/table';
import { Loader } from '../Loader/Loader';

const ClientsList = ({ clients, loading, handleSearchParams }) => {

  const getClientsHeader = () => {
    return [
      { name: "Client Name", ref: "clientName", enableSorting: true },
      { name: "Address", ref: "jobAddress", enableSorting: true },
      { name: "Square Foot", ref: "squareFeet", enableSorting: true },
    ];
  };

  const getClientsData = () => {
    if (!clients.length) return [{ clientName: "No clients available", jobAddress: "", edit: "", delete: "" }]

    return clients.map((client) => ({
      clientName: (
        <div className="flex items-center">
          <a className="hover:underline hover:text-primary" href={`./client-details/${client._id}`}>
            <p className="text-sm ">{client.clientName.toUpperCase()}</p>
          </a>
        </div>),
      jobAddress: (<p className="text-sm">{client.property.address}</p>),
      squareFeet: (<p className="text-sm">{client.property.squareFeet || 'N/A'}</p>),
    }))
  };

  const tableData = useMemo(() => ({
    tabledata: getClientsData(),
    headers: getClientsHeader(),
  }), [clients]);

  if (!clients.length) return <p className='text-xl font-semibold text-gray-500 text-center pt-14 w-full'>No clients to display here.</p>
  if (loading) return <Loader />

  return (
    <div className='flex p-6 w-full justify-center gap-4'>
      <Table
        {...tableData}
        handleSorting={handleSearchParams}
      // checkboxes={true}
      />
    </div>
  )
}

export { ClientsList };
