import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { axiosProtected } from '../utils/axios-interceptor'


const loginUser = async (credentials) => {
    const { data } = await axios.post(`${process.env.REACT_APP_IP}/user/login`, credentials)
    return data
}

export const rotateToken = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_IP}/user/rotateToken`, {
        headers: {
            Authorization: `Bearer ${localStorage.token}`
        }
    })
    return data
}

export const getUser = async () => {
    const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/user/getuser`)
    return data
}

export const logoutUser = () => {
    localStorage.removeItem('token')
    window.location.reload()
}

export const useLogin = () => {
    return useMutation({
        mutationFn: loginUser,
        onSuccess: data => {
            localStorage.setItem('token', data.token)
        },
    })
}

export const useUser = () => {
    return useQuery({ queryKey: ['user'], queryFn: getUser })
}

export const isVerifiedUser = (email) => {
    return axios.get(`${process.env.REACT_APP_IP}/user/isVerified/${email}`)
}

export const getUserTechnicians = (userId) => {
    return axiosProtected.get(`${process.env.REACT_APP_IP}/user/getUserTechnicians`, {
        userId
    })
}

export const getOwnerUser = (ownerUserId) => {
    console.log({ ownerUserId })
    return axiosProtected.get(`${process.env.REACT_APP_IP}/user/getOwnerUser/${ownerUserId}`)
}

export const getTechnicianByUser = (userId) => {
    return axiosProtected.get(`${process.env.REACT_APP_IP}/user/getTechnician/${userId}`)
}

export const createUserTechnicians = (email, role, username, password, passwordConfirm, status, ownerUserId) => {
    return axiosProtected.post(`${process.env.REACT_APP_IP}/user/createTechnician`, {
        email, role, username, password, passwordConfirm, status, ownerUserId
    })
}

export const verifyEmail = (email) => {
    return axios.get(`${process.env.REACT_APP_IP}/user/verify-email/${email}`)
}

export const deleteTechnician = (technicianId) => {
    return axiosProtected.delete(`${process.env.REACT_APP_IP}/user/deleteTechnician/${technicianId}`)
}