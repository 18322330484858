import { useSearchParams } from "react-router-dom";
import { SearchBar } from "../Search/SearchBar";

const ClientFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleSearchParams = (key, value) => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set(key, value);
    setSearchParams(updatedParams);
  };

  return (
    <div className="p-4 w-full">
      <SearchBar handleSearchParams={handleSearchParams} />
    </div>
  )
}

export { ClientFilters };
