import { ChevronLeftIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PrimaryButton } from '../../../components/Button/PrimaryButton'
import { Details } from '../../../components/clients/client-details/details'
import { JobsTable } from '../../../components/clients/client-details/jobs-table'
import DashboardLayout from '../../../components/DashboardLayout'
import { useApiOperation } from '../../../hooks/useApiOperation'
import { ClientsApi } from '../../../lib/clients.api'
import { DeletePopup } from '../../../components/Popups/DeletePopup'

const initialState = {
  clientName: '',
  email: '',
  property: {
    address: '',
    mapCenter: {
      lat: 0,
      lng: 0
    }
  }
}

const ClientDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [clientDetails, setClientDetails] = useState(initialState)
  const [deleteModal, setDeleteModal] = useState(false)
  const { startApiOperation, terminateApiOperation } = useApiOperation()

  const deleteClient = async () => {
    try {
      await ClientsApi.deleteClient(id);
      setDeleteModal(false)
      navigate('/clients')
    } catch (error) {
      console.error(error)
    }
  }

  const fetchDetails = async () => {
    try {
      startApiOperation()
      const details = await ClientsApi.fetchSingleClient({ clientId: id })
      setClientDetails(details)
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation([error])
    }
  }


  useEffect(() => {
    fetchDetails()
  }, [id])

  const DisplayClientDetails = () => {
    if (!clientDetails.email.length) return <div className='flex items-center justify-center h-screen'><p className='text-center text-2xl font-semibold text-gray-500'>Client Not Found</p></div>

    return (
      <div className='w-full flex-col gap-2 flex flex-wrap divide-y p-2 sm:p-5'>
        <div className='flex items-center justify-between'>
          <PrimaryButton
            onClickEvent={() => navigate('/clients')}
            label={'Back'}
            icon={<ChevronLeftIcon className='w-4 h-4' />}
            classNames='gap-x-1 items-start ' />

          <div className='flex flex-wrap gap-1 sm:gap-4 mr-0 sm:mr-8'>
            <PrimaryButton
              label='Edit'
              icon={<PencilIcon className='w-4 text-gray-100 ' />}
              onClickEvent={() => navigate(`/client/${id}`)}
            />
            <PrimaryButton
              label='Delete'
              icon={<TrashIcon className='w-4 text-gray-100' />}
              onClickEvent={() => setDeleteModal(true)}
            />
          </div>
        </div>
        <Details clientDetails={clientDetails} />
        <JobsTable propertyId={clientDetails.property._id} />
      </div>
    )
  }

  return (
    <DashboardLayout>
      <DeletePopup
        onDelete={deleteClient}
        label='Delete Client'
        desc='Are you sure you want to delete this client?'
        open={deleteModal}
        setOpen={setDeleteModal}
      />
      <DisplayClientDetails />
    </DashboardLayout>
  )
}

export default ClientDetails