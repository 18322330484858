import React from 'react'
import { Input } from '../Input/Input'

const AddEditForm = ({ clientDetails, handleChange }) => {
  return (
    <div className='flex flex-col gap-4 mt-3'>
      <Input
        options={{
          label: 'Client Name',
          name: "name",
          id: "name",
          value: clientDetails.clientName,
          onChange: (e) => handleChange('clientName', e.target.value),
          type: 'text',

        }}
      />
      <Input
        options={{
          label: 'Email',
          name: "email",
          id: "email",
          value: clientDetails.email,
          onChange: (e) => handleChange('email', e.target.value),
          type: 'text'
        }}
      />
    </div>
  )
}

export { AddEditForm }
