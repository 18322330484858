import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getValuesFromParams } from "../../utils/helpers";
import { FiltersDropDownMenu } from "./FiltersDropDownMenu";

const AppliedFilters = ({
  hardwareDropdownOptions,
  technicianDropdownOptions,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedHardwares, setSelectedHardwares] = useState([]);
  const [selectedTechnicians, setSelectedTechnicians] = useState([]);

  useEffect(() => {
    setSelectedHardwares(getValuesFromParams(searchParams, "hwid") || []);
    setSelectedTechnicians(getValuesFromParams(searchParams, "technicianId") || []);
  }, [searchParams]);

  return (
    <div className="flex flex-wrap gap-4 mb-6">
      <FiltersDropDownMenu
        title="Hardware"
        options={hardwareDropdownOptions}
        appliedOptions={selectedHardwares}
        setAppliedOptions={setSelectedHardwares}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      <FiltersDropDownMenu
        title="Technician"
        options={technicianDropdownOptions}
        appliedOptions={selectedTechnicians}
        setAppliedOptions={setSelectedTechnicians}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    </div>
  );
};

export { AppliedFilters };
