import { useNavigate } from 'react-router-dom'
import Gear from '../../../assets/Gear.svg'
import { useApiOperation } from '../../../hooks/useApiOperation'
import { JobsApi } from '../../../lib/jobs.api'

export const JobInProgress = ({ jobId, startTime }) => {
    const navigate = useNavigate()
    const { startApiOperation, terminateApiOperation } = useApiOperation()

    const handleCompleteJob = async () => {
        try {
            startApiOperation()
            const jobEndTime = new Date()
            await JobsApi.endJob({ id: jobId, startTime: startTime, endTime: jobEndTime })
            navigate(`/jobDetails/${jobId}`);
            terminateApiOperation()
        } catch (error) {
            terminateApiOperation([error])
        }
    }

    return (
        <div className="block mt-20 text-center">
            <img src={Gear} alt="job in progress" className="mx-auto" />
            <p className="text-4xl md:text-8xl font-light">Your job is in progress</p>
            <button className="bg-red-600 px-20 py-2 text-white font-medium text-lg rounded-md mt-10 hover:shadow-[0_0_20px_rgba(220,38,38,1)] transition-all duration-300"
                onClick={handleCompleteJob}>
                Complete Job
            </button>
        </div>
    )
}
