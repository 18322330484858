import format from "date-fns/format";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Loader } from "../../../../components/Loader/Loader";
import AddressesTimeline from "../../../../components/MapView/AddressesTimeline";
import { ErrorAlert } from "../../../../components/Popups/ErrorAlert";
import { useApiOperation } from "../../../../hooks/useApiOperation";
import { useUser } from "../../../../hooks/useAuth";
import { HardwaresApi } from "../../../../lib/hardwares.api";
import { JobsApi } from "../../../../lib/jobs.api";
import { dateFormat } from "../../../../utils/constants";
import { getEndOfDay, getStartOfDay } from "../../../../utils/helpers";
import FiltersMapLayout from "../filters-map-layout";

const initialDateRange = {
  startDate: format(getStartOfDay(new Date()), dateFormat),
  endDate: format(getEndOfDay(new Date()), dateFormat),
};

const defaultParams = {
  hardwareId: '',
  startDate: initialDateRange.startDate,
  endDate: initialDateRange.endDate,
};

const MapView = () => {
  const [jobsData, setJobsData] = useState([]);
  const [propertyId, setPropertyId] = useState(null);
  const [hardwares, setHardwares] = useState([]);
  const { data: userData } = useUser();
  const [searchParams] = useSearchParams(defaultParams);
  const { startApiOperation, terminateApiOperation, apiError, apiLoading } = useApiOperation();

  useEffect(() => {
    getUniqueJobs();
  }, [searchParams, userData]);

  useEffect(() => {
    fetchHardwares();
  }, []);

  const fetchHardwares = async () => {
    try {
      startApiOperation()
      const responseData = await HardwaresApi.fetchHardwares();
      setHardwares(responseData);
      terminateApiOperation()
    } catch (error) {
      console.error("Error fetching hardwares:", error);
      terminateApiOperation(['An error occurred while getting hardwares'])
    }
  };

  const selectedHardwares = () => {
    const selectedHardwareId = searchParams.get("hardwareId") ? searchParams.get("hardwareId").split(",") : [];
    return selectedHardwareId[0];
  };

  const getUniqueJobs = async () => {
    try {
      startApiOperation();
      const requestParams = {
        ...Object.fromEntries(searchParams.entries()),
        hardwareId: selectedHardwares(),
        user: userData?.user?._id,
        role: userData?.user?.role,
      };
      const data = await JobsApi.getUniqueJobs(requestParams);
      setJobsData(data);
      terminateApiOperation();
    } catch (error) {
      terminateApiOperation(["An error occurred while getting jobs. Please try again later"]);
    }
  };

  if (apiError) return <ErrorAlert errorMessages={apiError} />;

  const DataLoader = () => {
    if (apiLoading && !jobsData) return <Loader />;
    return null;
  };

  const NoJobsFound = () => {
    if (!jobsData.length) return <p className="text-center mt-4 ">Nothing to show here</p>
  }

  return (
    <FiltersMapLayout jobsData={jobsData} propertyId={propertyId} setPropertyId={setPropertyId} hardwares={hardwares}>
      <DataLoader />
      <div className="bg-white rounded-lg md:w-1/4 w-full lg:w-2/5 min-w-[250px] mt-6 sm:mt-0 overflow-auto border">
        <NoJobsFound />
        <AddressesTimeline properties={jobsData} searchParams={searchParams} />
      </div>
    </FiltersMapLayout>
  )
}

export default MapView
