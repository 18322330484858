import { HTTP_METHODS } from "../utils/constants";
import { sendRequest } from "../utils/request-service";

const API_REQUESTS = {
  CREATE_PROPERTY: {
    path: '/property/createproperty',
    method: HTTP_METHODS.POST,
  },

}

export const PropertiesApi = {

  createProperty: (data) => {
    return sendRequest(
      API_REQUESTS.CREATE_PROPERTY.method,
      `${API_REQUESTS.CREATE_PROPERTY.path}`,
      data
    );
  },
}
