import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useApiOperation } from '../../../hooks/useApiOperation';
import { JobsApi } from '../../../lib/jobs.api';
import { Table } from '../../../utils/shared/table/table';
import { Loader } from '../../Loader/Loader';


const getJobsHeader = () => {
  return [
    { name: "Address", ref: "address" },
    // { name: "Chemicals", ref: "chemicals" },
    { name: "Status", ref: "status" },
    // { name: "Technician     ", ref: "technician" },
    { name: "Date", ref: "date" },
  ];
};

const JobsTable = ({ propertyId }) => {
  const [jobs, setJobs] = useState([])
  const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation()

  useEffect(() => {
    fetchJobs()
  }, [propertyId])

  const fetchJobs = async () => {
    try {
      startApiOperation()
      const data = await JobsApi.getJobsOnProperty({ propertyId: propertyId })
      setJobs(data);
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation(['An error occurred while fetching jobs. Please try again later.'])
    }
  };

  const getJobsData = () => {
    const currentData = [];

    jobs?.map((job) => {
      return currentData.push({
        address: (
          <div className="flex items-center">
            <a className="hover:text-blue-500" href={`/job-details/${job?._id}`}>
              <p className="text-sm">{job?.address?.address}</p>
            </a>
          </div>
        ),
        // Chemicals: (
        //   <div>
        //     {job?.chemicals?.map((chemical) => (
        //       <p key={chemical._id} className="text-sm">{chemical.chemicalName}</p>
        //     ))}
        //   </div>
        // ),
        status: (
          <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${job?.active === true ? "bg-blue-100 text-blue-800" : "bg-green-100 text-green-800"}`}>
            {job?.active === true ? "In-Progress" : "Completed"}
          </span>
        ),
        // technician: (job?.user ? job?.user.username : job?.technician[0]?.username) ?? job?.user?.username,
        date: dayjs(job.startTime).format("DD MMM YYYY"),
        actions: <></>,
      });
    });

    return currentData;
  };

  const tableData = {
    headers: getJobsHeader(),
    tabledata: getJobsData(),
  };

  if (apiLoading) return <Loader />

  return (
    <Table {...tableData} />
  )
}

export { JobsTable };
