import { LockClosedIcon } from '@heroicons/react/24/solid';
import joi from 'joi';
import { useEffect, useState } from 'react';
import { ErrorAlert } from '../components/Popups/ErrorAlert';
import { isVerifiedUser, useLogin } from '../hooks/useAuth';

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [validationError, setValidationError] = useState('')
    const { mutate: login } = useLogin()

    const schema = joi.object({
        email: joi.string().regex(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).required(),
        password: joi.string().required()
    })

    const valid = () => {
        const result = schema.validate({ email, password })
        if (!result.error) {
            return true
        } else {
            setValidationError(result.error.message)
        }
    }
    const handleSubmit = async (e) => {
        // Prevent refresh
        e.preventDefault()

        const { data: isVerified } = await isVerifiedUser(email)

        if (!isVerified) {
            setValidationError("Your email is not verified");
            return;
        }

        if (valid() && isVerified) {
            login({ email, password }, {
                onError: ({ response }) => setValidationError(response.data.message),
                onSuccess: () => { window.location.reload() }
            })
        }
    }

    useEffect(() => {
        setValidationError('')
    }, [email, password])

    return (
        <section className='h-screen bg-gray-50 flex items-center justify-center'>
            <div className='p-4 max-w-md w-full rounded-lg'>
                <img
                    className="mx-auto h-12"
                    src='/smartSpray-logo.png'
                    alt="Smart Spray Logo"
                />
                <h2 className='text-center text-3xl font-bold text-gray-900'>Sign in to your account</h2>
                <form className='space-y-8' onSubmit={handleSubmit}>
                    <div className='rounded-md shadow-sm -space-y-px'>
                        <div className='mt-4'>
                            <input className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:ring-1 focus:border-indigo-500 focus:z-10 sm:text-sm' type="email" autoComplete='email' placeholder='Email Address' onChange={(e) => setEmail(e.target.value)}
                                value={email} />
                        </div>
                        <div>
                            <input className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:ring-1 focus:z-10 sm:text-sm' type="password" autoComplete='current-password' placeholder='Password' onChange={(e) => setPassword(e.target.value)}
                                value={password} />
                        </div>
                    </div>
                    <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3"  >
                            <LockClosedIcon className='text-white w-4' />
                        </span>Sign in</button>
                </form>
                <div className='mt-5'>
                    {validationError && <ErrorAlert errorMessages={validationError} />}
                </div>
            </div>
        </section>
    )
}

export default Login