import React from 'react';
import { useDispatch } from 'react-redux';
import { setNotifications } from '../../features/notificationsSlice';
import { useApiOperation } from '../../hooks/useApiOperation';
import { ChemicalsApi } from '../../lib/chemicals.api';
import { NOTIFY_TYPE } from '../../utils/constants';
import { PopupBox } from '../Popups/PopupBox';
import { AddEditForm } from './addEditForm';



const EditChemical = ({ setSelectedChemical, selectedChemical, reFetch, openModal, setOpenModal }) => {
  const { chemicalName, description, concentration, } = selectedChemical
  const dispatch = useDispatch()
  const { startApiOperation, terminateApiOperation } = useApiOperation()

  const validateInputs = () => {
    const errors = [];
    if (!chemicalName) errors.push('Chemical name is required');
    if (!description) errors.push('Chemical description is required');
    if (!concentration) errors.push('Concentration is required');

    return errors;
  };

  const handleSubmit = async () => {
    try {
      startApiOperation()
      const errors = validateInputs();
      if (errors.length) return dispatch(setNotifications({ type: NOTIFY_TYPE.Error, messages: errors }));
      await ChemicalsApi.updateChemical(selectedChemical);
      setOpenModal(false);
      reFetch();
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation([
        'Something went wrong while updating chemical. Please try again later',
      ]);
    }
  }

  return (
    <>
      <PopupBox modal={openModal} handleClose={() => setOpenModal(false)} title='Edit chemical' >
        <AddEditForm
          chemical={selectedChemical}
          setChemical={setSelectedChemical}
          onSubmit={handleSubmit}
        />
      </PopupBox>
    </>
  )
}
export default EditChemical
