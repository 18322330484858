import { PlusIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { mergeClasses } from '../../utils/helpers';

const defaultButtonStyles = 'bg-blue-500 px-3 py-2 rounded-lg text-white text-sm hover:bg-blue-600 transition-all flex items-center gap-x-1.5 min-w-24 justify-center'
export function PrimaryButton({ label, onClickEvent, displayPlus, icon = '', classNames = '' }) {
    const buttonClasses = mergeClasses(defaultButtonStyles, classNames);
    return (
        <button className={buttonClasses} onClick={onClickEvent}>
            {displayPlus && <PlusIcon className='w-4' />}
            <span>{icon}</span>
            <span className='font-medium'>
                {label}
            </span>
        </button>
    )
}
