import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { JobsApi } from "../../lib/jobs.api";
import { PrimaryButton } from "../Button/PrimaryButton";

const DisplayAddNewNote = ({ active, noteText, setNoteText, handleSaveNotes }) => {
  if (!active) return null

  return (<div className="w-full">
    <label className="flex justify-start items-center text-[14px] mb-2">
      <span>Add New Note</span>
    </label>
    <textarea
      rows={3}
      className="border border-gray-300 focus-visible:outline-none focus:border-blue-600 hover:border-blue-400 w-full p-2 rounded-lg"
      value={noteText}
      onChange={(e) => setNoteText(e.target.value)} />
    <PrimaryButton label={'Save Note'} classNames="mt-2" onClickEvent={handleSaveNotes} />
  </div>)
}

export const AddNotes = ({ job, setShouldRefetch, jobId }) => {
  const [noteText, setNoteText] = useState('')
  const [active, setActive] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [editIndex, setEditIndex] = useState(null);

  const handleSaveNotes = async () => {
    try {
      if (!noteText.trim()) return;
      const updatedNotes = [...job?.notes];
      if (editIndex !== null) {
        updatedNotes[editIndex] = noteText;
      } else {
        updatedNotes.push(noteText);
      }

      await JobsApi.addNotes({ id: jobId, notes: updatedNotes });
      setNoteText('');
      setEditIndex(null);
      setActive(false)
      setIsEditable(false)
      setShouldRefetch(true);
    } catch (error) {
      console.error("Failed to save notes", error);
    }
  };

  const handleEditNote = (note, index) => {
    setActive(true)
    setNoteText(note)
    setEditIndex(index)
  }

  const DisplayAddEditButtons = () => {
    return (
      <div className="flex flex-wrap gap-2 sm:gap-4 py-4">
        <button className="border bg-white px-2 sm:px-3 py-2 rounded-lg flex gap-2 items-center hover:bg-gray-100" onClick={() => setActive(!active)}>
          <PlusIcon className="w-4 sm:w-6 h-4 sm:h-6" />
          <span className="text-sm sm:text-base">Add Notes</span>
        </button>
        <button className="border bg-white px-2 sm:px-3 py-2 rounded-lg flex gap-2 items-center hover:bg-gray-100" onClick={() => setIsEditable(!isEditable)}>
          <PencilIcon className="w-4 sm:w-6 h-4 sm:h-6" />
          <span className="text-sm sm:text-base">Edit Notes</span>
        </button>
      </div>
    )
  }

  const DisplayNotes = ({ notes }) => {
    if (!notes || !notes.length) return
    return (
      <div className="mt-2">
        <h1 className="font-medium text-lg ">Notes:</h1>
        <ol className=" py-1 list-decimal" >
          {job?.notes.map((note, index) => (
            <li key={index} className="pt-1 mt-1 flex items-center gap-2">
              <div className="bg-gray-100 border-gray-300 border rounded-md px-3 py-1 rounded-r-full w-fit">
                {note}
              </div>
              {isEditable && <div
                onClick={() => handleEditNote(note, index)}
                className="rounded-full bg-gray-100 h-fit p-1.5 border-gray-800 border cursor-pointer hover:bg-gray-200 active:scale-90">
                <PencilIcon className="w-4 h-4" />
              </div>
              }
            </li>
          ))}
        </ol>
      </div>
    )
  }

  return (
    <div className="mt-6 space-y-4 gap-5 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3 ">
      <div className="flex overflow-hidden rounded-lg bg-white shadow-sm border-gray-300 border p-6">
        <div className="p-0 sm:p-3 w-full">
          <h3 className="text-xl font-medium">Internal Notes</h3>
          <DisplayAddEditButtons />
          <DisplayAddNewNote active={active} handleSaveNotes={handleSaveNotes} noteText={noteText} setNoteText={setNoteText} />
          <DisplayNotes notes={job?.notes ?? []} />
        </div>
      </div>
    </div>
  )
}