import React, { useEffect, useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useApiOperation } from '../../hooks/useApiOperation';
import { useUser } from '../../hooks/useAuth';
import { JobsApi } from '../../lib/jobs.api';

function GoogleAutocompleteInput({ setJobDetails, address, label = true }) {
    const { startApiOperation, terminateApiOperation } = useApiOperation()
    const { data: userData } = useUser()
    const [disableInput, setDisableInput] = useState(false)
    const {
        value,
        setValue,
        suggestions: { data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: ['us', 'ca'] }
        }
    });


    const onClickAddress = async (description) => {
        setValue(description, false);
        clearSuggestions()

        const results = await getGeocode({ address: description })
        const { lat, lng } = await getLatLng(results[0])
        setJobDetails('mapCenter', { lat: lat, lng: lng })
        onClickSearch(description)
    }

    const onClickSearch = async (description) => {
        try {
            startApiOperation()
            const requestParams = {
                address: value,
                user: userData?.user?._id
            }
            const job = await JobsApi.isJobActive(requestParams)
            const { isActive } = job
            if (isActive) {
                //TODO: nav('/jobInProgress/')
            } else {
                if (value) {
                    setJobDetails('address', description)
                    setDisableInput(true)
                }
            }
            terminateApiOperation()
        } catch (error) {
            terminateApiOperation(['Something went wrong. Please try again later.'])
        }
    }

    useEffect(() => {
        setValue(address, false)
    }, [])

    const DisplayLabel = () => {
        if (!label) return null
        return <label htmlFor="google-input" className='text-gray-500 block text-center text-lg pb-2'>Search which address you would like to start a job for</label>
    }

    return (
        <div className='w-full xl:w-fit '>
            <DisplayLabel />
            <div className="w-full max-w-3xl relative ">
                <input type="text" id='google-input' className='border border-gray-300 rounded-md px-4 py-2 xl:min-w-[32rem] 2xl:min-w-[47rem] w-full focus-visible:outline-none max-h-14'
                    value={value ?? address}
                    disabled={disableInput}
                    onChange={(e) => setValue(e.target.value)}
                />
                {data.length > 0 && <div className="bg-white rounded-b-xl border divide-y absolute w-full">
                    {data.map(({ place_id, description }) => (
                        <div key={place_id}
                            className="py-2 px-2 text-sm cursor-pointer hover:bg-gray-100"
                            onClick={() => onClickAddress(description)}
                        >
                            {description}
                        </div>
                    ))}
                </div>}
            </div>
        </div>
    )
}

export default React.memo(GoogleAutocompleteInput);