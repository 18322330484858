import React, { useState } from 'react'
import { PrimaryButton } from '../Button/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import { SecondaryButton } from '../Button/SecondaryButton'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { FileUpload } from '../versions/FileUpload/file-upload'
import { PopupBox } from '../Popups/PopupBox'
import { useApiOperation } from '../../hooks/useApiOperation'
import { ClientsApi } from './../../lib/clients.api'
import { NOTIFY_TYPE } from '../../utils/constants'
import { Loader } from '../Loader/Loader'

const AddClients = ({ reFetch }) => {
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation()

  const DisplayLoaderOrUploader = () => {
    if (!apiLoading) return <FileUpload setFileSelect={setUploadedFiles} files={uploadedFiles} />
    return <Loader height='h-full' />
  }

  const DisplayFileUploader = () => {
    return (
      <PopupBox modal={openModal} handleClose={() => setOpenModal(false)} title='Upload Clients from your system' >
        <div className='py-8 px-10'>
          <DisplayLoaderOrUploader />
        </div>
        <div className='flex justify-center'>
          <PrimaryButton
            label='Upload'
            onClickEvent={uploadClients}
            icon={<ArrowUpTrayIcon className='w-5 h-5 mr-1' />}
          />
        </div>
      </PopupBox>
    )
  }


  const uploadClients = async () => {
    try {
      startApiOperation()
      const formData = new FormData();
      formData.append('file', uploadedFiles[0]);
      const response = await ClientsApi.bulkUploadClient(formData)
      setUploadedFiles([]);
      setOpenModal(false)
      await reFetch()
      terminateApiOperation([response.message], NOTIFY_TYPE.Success)
    } catch (error) {
      terminateApiOperation([error])
    }
  }



  return (
    <div className='flex justify-end w-full p-4 gap-3'>
      <DisplayFileUploader />
      <SecondaryButton
        label='Upload Clients'
        icon={<ArrowUpTrayIcon className='w-5 h-5 mr-1' />}
        onClick={() => setOpenModal(true)}
      />
      <PrimaryButton
        label='Add New Client'
        onClickEvent={() => navigate('/clients/add-new')}
        displayPlus
      />
    </div>
  )
}

export { AddClients }